import { DataSection6 } from "../../../data/Mydata";
import HomePageCard from "../../common/HomePageCard/HomePageCard";

export default function Section6({setpageNumber , pageNumber}){
    return(
    <div className="">
        <h1 className="fw-bold text-center mb-5">What is your purpose of traveling to Dubai ?</h1>
        <div className="d-flex justify-content-center align-items-center flex-wrap">
            {
                DataSection6.map(newData => <HomePageCard {...newData} setpageNumber={setpageNumber} pageNumber={pageNumber}/>)
            }
        </div>
    </div>
    )
}