import React, { createContext, useContext, useState } from 'react';

const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const [SendData, setSendData] = useState({
        section1: {
            textdata: ''
        },
        section2: {
            Date1: '',
            Date2:''
        },
        section3: {
            trip: '',
            pets: false,
            children: false
        },
        section4: {
            data: ''
        },
        section5: {
            interested : []
        },
        section6: {
            data: ''
        }
    });

    const updateSection = (sectionKey, newData) => {
        setSendData(prevData => ({
            ...prevData,
            [sectionKey]: {
                ...prevData[sectionKey],
                ...newData
            }
        }));
    };

    return (
        <DataContext.Provider value={{ SendData, updateSection }}>
            {children}
        </DataContext.Provider>
    );
};


export const useDataContext = () => {
    return useContext(DataContext);
};