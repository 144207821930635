import './section4.css'
import {DataSection4} from '../../../data/Mydata'
import HomePageCard from '../../common/HomePageCard/HomePageCard'
export default function Section4({setpageNumber , pageNumber}){
    return(
        <div className=''>
            <p className='text-center fs-3 fw-bold mb-5'>What`s your budget</p>
            <div className='d-flex justify-content-center align-items-center mx-auto flex-wrap'>
                {
                    DataSection4.map(data => <div><HomePageCard {...data} setpageNumber={setpageNumber} pageNumber={pageNumber}/></div>)
                }
            </div>
        </div>
    )
}