import { Data } from "../../../data/Mydata";
import HomePageCard from "../../common/HomePageCard/HomePageCard";

export default function Section1({setpageNumber , pageNumber}){
    return(
        <div>
            <h1 className="fw-bold text-center mb-5">Tell me about yourself:</h1>
            <div className="d-flex justify-content-center flex-wrap align-items-center">
                {
                    Data.map(newData => <HomePageCard {...newData} setpageNumber={setpageNumber} pageNumber={pageNumber}/>)
                }
            </div>
        </div>
    )
}