import { useEffect, useState } from 'react';
import Section1 from '../../components/layout/sec1/Sec1'; 
import Section2 from '../../components/layout/Section2/sec2';
import Section3 from '../../components/layout/Section3/sec3';
import Section4 from '../../components/layout/Section4/section4';
import Section5 from '../../components/layout/section5/sec5';
import Section6 from '../../components/layout/Section6/sec6';
import './Home.css'; 
import { RiArrowRightDoubleLine , RiArrowLeftDoubleLine} from "react-icons/ri";
import ProgressBar from '../../components/common/progressBar/ProgressBar';


export default function Home(){
    const [pageNumber , setpageNumber]=useState(1);
    const pageNumberdecrease = ()=>{
        if(1 < pageNumber){
            setpageNumber((prev) => prev - 1);
        }
    }

    const pageNumberadd = ()=>{
        if(6 > pageNumber){
            setpageNumber((prev) => prev + 1);
        }
    }

    useEffect(()=>{
        if(pageNumber === 7){
            setpageNumber(6)
        }
    },[pageNumber])
    
    return(
        //home page Section
        <div>
            <ProgressBar pageNumber={pageNumber}/>
            <div className='home-layout'>
                <div className={pageNumber === 1 ? 'd-block' : 'd-none'}>
                    <Section1 setpageNumber={setpageNumber} pageNumber={pageNumber}/>
                </div>
                <div className={pageNumber === 3 ? 'd-block' : 'd-none'}>
                    <Section2 />
                </div>
                <div className={pageNumber === 4 ? 'd-block' : 'd-none'}>
                    <Section3 />
                </div>
                <div className={pageNumber === 5 ? 'd-block' : 'd-none'}>
                    <Section4 setpageNumber={setpageNumber} pageNumber={pageNumber}/>
                </div>
                <div className={pageNumber === 6 ? 'd-block' : 'd-none'}>
                    <Section5 />
                </div>
                <div className={pageNumber === 2 ? 'd-block' : 'd-none'}>
                    <Section6 setpageNumber={setpageNumber} pageNumber={pageNumber}/>
                </div>
            </div>
            <div className='home-bottom-section'>
                <button onClick={pageNumberdecrease} className='d-flex justify-content-center align-items-center ms-5 rounded px-4 py-1 button-color'> <RiArrowLeftDoubleLine /> Prev</button>
                <div>{pageNumber} of 6</div>
                {
                    pageNumber !== 6 ? <button onClick={pageNumberadd} className='me-5 rounded px-4 py-1 button-color'>Next <RiArrowRightDoubleLine /></button> :<a href='https://www.tripadvisor.com/TripDetails-t139653605'><button onClick={pageNumberadd} className='me-5 rounded px-4 py-1 button-color'>Next <RiArrowRightDoubleLine /></button></a>
                }
            </div>
        </div>
    )
}