import img1  from '../asset/img/homeSection/image1.jpg';
import img2  from '../asset/img/homeSection/image2.jpg';
import img3  from '../asset/img/homeSection/image3.jpg';
import Section4Img1 from '../asset/img/section4/img1.jpg';
import Section4Img2 from '../asset/img/section4/img2.jpg';
import Section4Img3 from '../asset/img/section4/img3.jpg';
import Section6Img1 from '../asset/img/section6/image1.jpg';
import Section6Img2 from '../asset/img/section6/image2.jpg';
import Section6Img3 from '../asset/img/section6/image3.jpg';
import Section6Img4 from '../asset/img/section6/image4.jpg';

export const Data = [
    {
        id:1,
        code :1,
        img : img1,
        title : 'Got plans to visit Dubai ?'
    },
    {
        id:1,
        code :2,
        selected : 'HomePageCard-seleted',
        img : img2,
        title : 'Are you in Dubai right now ?'
    },
    {
        id:1,
        code :3,
        selected : 'HomePageCard-seleted',
        img : img3,
        title : 'Or are you just interested in Dubai?'
    }
];

export const DataSection4 = [
    {
        id:2,
        code : 1,
        img : Section4Img1,
        title : 'Budget traveller'
    },
    {
        id:2,
        code : 2,
        img : Section4Img2,
        title : 'Looking for luxury'
    },
    {
        id:2,
        img : Section4Img3,
        code : 3,
        title : 'Rolling in cash'
    }
]

export const DataSection5 =[
    {
        id : 1,
        name : 'Must-see Attractions'
    },
    {
        id : 2,
        name : 'Great Food'
    },
    {
        id : 3,
        name : 'Hidden Gems'
    },
    {
        id : 4,
        name : 'Dubai souks & sightseeing tours'
    },
    {
        id : 5,
        name : 'Burj Khalifa skyline views'
    },
    {
        id : 6,
        name : 'Desert safari adventures'
    },
    {
        id : 7,
        name : 'Luxury Shopping'
    },
    {
        id : 8,
        name : 'Middle Eastern Cuisine'
    },
    {
        id : 9,
        name : 'Architectural Wonders'
    },
    {
        id : 10,
        name : 'Cultural Landmarks'
    },
    {
        id : 11,
        name : 'Water Adventure'
    },
    {
        id : 12,
        name : 'Nightlife and Entertainment'
    },
]


export const DataSection6 =[
    {
        id:3,
        code : 1,
        img : Section6Img1,
        title : 'Business',
    },
    {
        id:3,
        code : 2,
        img : Section6Img2,
        title : 'Participate in the event'
    },
    {
        id:3,
        code : 3,
        img : Section6Img3,
        title : 'Leisure'
    },
    {
        id:3,
        code : 4,
        img : Section6Img4,
        title : 'Visit fiends & relatives'
    }
];