import './sec5.css'
import { DataSection5 } from "../../../data/Mydata";
import CheckBoxSection5 from "../../common/Section5CheckBox/CheckBoxSection5";
import { FaPlus } from "react-icons/fa";
import { TiDelete } from "react-icons/ti";
import { useRef, useState } from 'react';
import { useDataContext } from '../../../context/DataContext';
import Swal from 'sweetalert2'

export default function Section5(){
    const { SendData, updateSection } = useDataContext();
    const[closeModal , setcloseModal]=useState(false);
    const[Value , setValue]=useState('');
    const[disabledBTN , setdisabledBTN]=useState(false);
    const Ref = useRef(null);

    const currentInterested = SendData.section5.interested;

    const ModalHandler = ()=>{
        !closeModal ? setcloseModal(true) : setcloseModal(false)
    }

    const BtnHandller =()=>{
        Ref.current.style.background = 'rgba(255,255,255,0.2)';
        Ref.current.style.color = 'rgba(0,0,0,0.7)';
        setdisabledBTN(true);

        const updatedInterested = [...currentInterested, Value];
        updateSection('section5', { interested: updatedInterested });
        ModalHandler();
        Swal.fire({
            position: "center",
            icon: "success",
            title: "saved interest",
            showConfirmButton: false,
            timer: 2500
          });
    }

    const textAreaHandller =(e) =>{
        const value = e.target.value
        setValue(value);
    }

    return(
        <div className="text-center">
            <p className="fs-2 fw-bold">Tell us what you’re interested in</p>
            <p className="">Select all that apply.</p>
            <div className="CheckBoxSection5-sizeSection5 mt-5 mx-auto d-flex justify-content-center align-items-center flex-wrap">
                {
                    DataSection5.map(data => <div key={data.name}><CheckBoxSection5 {...data}/> </div>)
                }
            </div>
            <div>
                <div>
                    <button ref={Ref} disabled={disabledBTN} onClick={ModalHandler} className="CheckBoxSection5-addBTN"><FaPlus /> Add Interest</button>
                </div>
                <div className={closeModal ? 'CheckBoxSection5-addInterst' : 'd-none'}>
                    <div className='CheckBoxSection5-addInterst-form'>
                        <p className='text-start ms-3 mb-0'><TiDelete onClick={ModalHandler} size={'3rem'}/></p>
                        <p className='fs-2 fw-bold text-center'>Tell us what you’re interested in</p>
                        <textarea onChange={textAreaHandller} placeholder='Enter additional interests'/>
                        <button onClick={BtnHandller} className='CheckBoxSection5-body-submit'>Add</button>
                    </div>
                </div>
            </div>
        </div>
    )
}