import { useRef } from 'react';
import { useDataContext } from '../../../context/DataContext';
import './checkBoxSection5.css';
export default function CheckBoxSection5({name}){
    const { SendData, updateSection } = useDataContext();
    const Ref = useRef(null)

    const checkBoxHanddler = (e)=>{
        const Id = e.target.id
        
        const currentInterested = SendData.section5.interested;
        if (currentInterested.includes(Id)){
            Ref.current.classList.remove('section5-select');
            const updatedInterested = currentInterested.filter(
                (item) => item !== Id
            );
            updateSection('section5', { interested: updatedInterested });
        } else {
            Ref.current.classList.add('section5-select');
            const updatedInterested = [...currentInterested, Id];
            updateSection('section5', { interested: updatedInterested });
        }
        console.log(SendData);
    }

    return(
        <div ref={Ref} onClick={checkBoxHanddler} id={name} className="CheckBoxSection5-container">
            <p id={name} className='p-0 m-0'>{name}</p>
        </div>
    )
}