import { useEffect, useState } from 'react';
import './ProgressBar.css';

export default function ProgressBar({pageNumber}){
    const [width , setwidth]=useState(0);
    const progressHandller = ()=>{   
        if(pageNumber > 0){
            setwidth(16.666 *pageNumber)  
        }  
    }

    useEffect(()=>{
        progressHandller();
    },[pageNumber])

    return(
        <div className='ProgressBar-container'>
            <span style={{width: `${width}%`}}></span>
        </div>
    )
}