import './App.css';
import Home from './pages/home/Home';
import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import GLOBE from "vanta/dist/vanta.globe.min";
import CLOUDS from "vanta/dist/vanta.clouds.min";

function App() {
  useEffect(() => {
    const vantaEffect = CLOUDS({
      el: "#my-vanta-div",
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 800.00,
      minWidth: 200.00,
      speed: 0.00,
      THREE
    });

    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, []);

  return (
      <div id="my-vanta-div">
          <Home />
      </div>
  );
}

export default App;
