import { useRef } from 'react';
import { useDataContext } from '../../../context/DataContext';
import './HomePageCard.css'

export default function HomePageCard({img , title , id , code ,setpageNumber , pageNumber}){
    const { SendData, updateSection } = useDataContext();
    const divRef = useRef(null);

    const handleChange = (e) => {
        const value = e.target.id;
        if(pageNumber <= 6){
            setpageNumber(pageNumber + 1)
        }
        
        if(id === 1){
            updateSection('section1', { textdata: `${value}` });
            //delete class
            const allCards = document.querySelectorAll('.HomePageCard-container');
            allCards.forEach(card => {
                card.classList.remove('HomePageCard-seleted');
            });
            // selected 
            if(code === 1){
                divRef.current.classList.add('HomePageCard-seleted');
            }else if(code === 2){
                divRef.current.classList.add('HomePageCard-seleted');
            }else if(code === 3){
                divRef.current.classList.add('HomePageCard-seleted');
            };

        }else if(id === 2){
            updateSection('section4', { data: `${value}` });
            //delete class
            const allCards = document.querySelectorAll('.HomePageCard-container');
            allCards.forEach(card => {
                card.classList.remove('HomePageCard-seleted');
            });
            // selected 
            if(code === 1){
                divRef.current.classList.add('HomePageCard-seleted');
            }else if(code === 2){
                divRef.current.classList.add('HomePageCard-seleted');
            }else if(code === 3){
                divRef.current.classList.add('HomePageCard-seleted');
            };
        }else if(id === 3){
            updateSection('section6', { data: `${value}` });
            //delete class
            const allCards = document.querySelectorAll('.HomePageCard-container');
            allCards.forEach(card => {
                card.classList.remove('HomePageCard-seleted');
            });
            // selected 
            if(code === 1){
                divRef.current.classList.add('HomePageCard-seleted');
            }else if(code === 2){
                divRef.current.classList.add('HomePageCard-seleted');
            }else if(code === 3){
                divRef.current.classList.add('HomePageCard-seleted');
            }else if(code === 4){
                divRef.current.classList.add('HomePageCard-seleted');
            };
        }
        console.log(SendData);
    };
    return(
        <div ref={divRef} onClick={handleChange} id={title} className="HomePageCard-container">
            <img onClick={handleChange} id={title} src={img}/>
            <div onClick={handleChange} id={title} className='p-1'>
                <p onClick={handleChange} id={title} className='fw-bold p-2 m-0'>{title}</p>
            </div>
        </div>
    )
}