import * as React from 'react';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import { useDataContext } from '../../../context/DataContext';

export default function Section2(){
    const { SendData, updateSection } = useDataContext();
    const DataHandller = (e)=>{
      let Data1 = e[0].$d
      let Data2 = e[1]?.$d

      updateSection('section2', { Date1: Data1 ,Date2 : Data2 });
      console.log(SendData);
    }
    return(
        <div className=''>
            <div className='mt-3'>
            <p className='text-center fs-2 fw-bold text-dark'>When are you going ?</p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer sx={{
                background:'rgba(255,255,255,0.6)',
                display : 'flex',
                justifyContent : 'center',
                alignItems:'center',
                width:'100%',
                minWidth:'250px',
                maxWidth:'640px',
                margin:'auto',
                borderRadius:'10px',
              }} components={['DateRangeCalendar', 'DateRangeCalendar']}>
                <DemoItem>
                  <DateRangeCalendar onChange={DataHandller} calendars={2} />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
            </div>
        </div>
    )
}