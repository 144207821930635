import './section3.css'
import { IoMan } from "react-icons/io5";
import { GiChainedHeart } from "react-icons/gi";
import { FaUserFriends } from "react-icons/fa";
import { MdFamilyRestroom } from "react-icons/md";
import { useRef, useState } from 'react';
import { useDataContext } from '../../../context/DataContext';
export default function Section3(){
    const [ButtonHandller ,setButtonHandller ] =useState('1');
    const { SendData, updateSection } = useDataContext();
    const Ref = useRef(null);
    const BTNRef = useRef(null);

    const [InputChecked , setInputChecked] = useState({
        input1 : true ,
        input2 : false ,
        input3 : false ,
        input4 : false 
    });

    const [BtnChecked , setBtnChecked] = useState({
        Pet : false ,
        child : false
    });

    const CheckboxHandller =(e)=>{
        const Id = e.target.id;
        const value = e.target.value;
        setButtonHandller(Id);
        updateSection('section3', { trip: value});

        if(Id === '1'){
            setInputChecked({
                input1 : true ,
                input2 : false ,
                input3 : false ,
                input4 : false 
            });
        }else if(Id === '2'){
            setInputChecked({
                input1 : false ,
                input2 : true ,
                input3 : false ,
                input4 : false 
            });
        }else if(Id === '3'){
            setInputChecked({
                input1 : false ,
                input2 : false ,
                input3 : true ,
                input4 : false 
            });
        }else if(Id === '4'){
            setInputChecked({
                input1 : false ,
                input2 : false ,
                input3 : false ,
                input4 : true 
            });
        }
        console.log(SendData)

    }

    const BTNHandller =(e)=>{
        const value = e.target.value;
        const name = e.target.name
        
        if(value === 'Are you traveling with pets?' && name === 'yes'){
            updateSection('section3', {pets: true});
            setBtnChecked({...BtnChecked , Pet : true});
        }else if(value === 'Are you traveling with pets?' && name === 'no'){
            updateSection('section3', {pets: false});
            setBtnChecked({...BtnChecked , Pet : false});
        }else if(value === 'Are you traveling with children?' && name === 'yes'){
            updateSection('section3', {children: true});
            setBtnChecked({...BtnChecked , child : true});
        }else if(value === 'Are you traveling with children?' && name === 'no'){
            updateSection('section3', {children: false});
            setBtnChecked({...BtnChecked , child : false});
        }

        console.log(SendData);
    }
    return(
        <div className=''>
            <p className='fs-3 fw-bold text-center'>What kind of trip are you planning?</p>
            <div className='Section3-layout'>
                <div className='Section3-layout-1'>
                    <input className={`Section3-layout-input ${InputChecked.input1 === true ? 'section3-background-select' : '' }`} ref={Ref} onClick={CheckboxHandller} value='Solo Trip ' id='1' type='radio'/>
                    <p className='Section3-title'><IoMan size={'1.5rem'}/></p>
                    <p className='Section3-text'>
                        Solo Trip 
                    </p>
                </div>
                <div className='Section3-layout-1'>
                    <input className={`Section3-layout-input ${InputChecked.input2 === true ? 'section3-background-select' : '' }`} ref={Ref} onClick={CheckboxHandller} value='Partner Trip' id='2' type='radio'/>
                    <p className='Section3-title'><GiChainedHeart size={'1.5rem'}/></p>
                    <p className='Section3-text'>
                        Partner Trip
                    </p>
                </div>
                <div className='Section3-layout-1'>
                    <input className={`Section3-layout-input ${InputChecked.input3 === true ? 'section3-background-select' : '' }`} ref={Ref} onClick={CheckboxHandller} value='Friends Trip' id='3' type='radio'/>
                    <p className='Section3-title'><FaUserFriends size={'1.5rem'}/></p>
                    <p className='Section3-text'>
                        Friends Trip
                    </p>
                </div>
                <div className='Section3-layout-1'>
                    <input className={`Section3-layout-input ${InputChecked.input4 === true ? 'section3-background-select' : '' }`} ref={Ref} onClick={CheckboxHandller} value='Family Trip' id='4' type='radio'/>
                    <p className='Section3-title'><MdFamilyRestroom size={'1.5rem'}/></p>
                    <p className='Section3-text'>
                        Family trip
                    </p>
                </div>
            </div>
            <div className='Section3-qu-container'>
                <div className={ButtonHandller === '1' ?'Section3-qu' : 'd-none'}>
                    <p>Are you traveling with pets?</p>
                    <div>
                        <button className={BtnChecked.Pet === true ?'' : 'section3-select' } ref={BTNRef} name='yes' value='Are you traveling with pets?' onClick={BTNHandller}>Yes</button>
                        <button className={BtnChecked.Pet === false ?'' : 'section3-select' } ref={BTNRef} name='no' value='Are you traveling with pets?' onClick={BTNHandller}>No</button>
                    </div>
                </div>
                <div className={ButtonHandller === '2' ?'Section3-qu' : 'd-none'}>
                    <p>Are you traveling with pets?</p>
                    <div>
                        <button className={BtnChecked.Pet === true ?'': 'section3-select' } ref={BTNRef}  name='yes' value='Are you traveling with pets?' onClick={BTNHandller}>Yes</button>
                        <button className={BtnChecked.Pet === false ?'' : 'section3-select' } ref={BTNRef}  name='no' value='Are you traveling with pets?' onClick={BTNHandller}>No</button>
                    </div>
                </div>
                <div className={ButtonHandller === '3' ?'Section3-qu' : 'd-none'}>
                    <div>
                        <p>Are you traveling with children?</p>
                        <div>
                            <button className={BtnChecked.child === true ?'' : 'section3-select'} ref={BTNRef}  name='yes' value='Are you traveling with children?' onClick={BTNHandller}>Yes</button>
                            <button className={BtnChecked.child === false ?'' :'section3-select' } ref={BTNRef}  name='no' value='Are you traveling with children?' onClick={BTNHandller}>No</button>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <p>Are you traveling with pets?</p>
                        <div>
                            <button className={BtnChecked.Pet === true ? '' : 'section3-select'}  ref={BTNRef}  name='yes' value='Are you traveling with pets?' onClick={BTNHandller}>Yes</button>
                            <button className={BtnChecked.Pet === false ?'' :'section3-select' } ref={BTNRef}  name='no' value='Are you traveling with pets?' onClick={BTNHandller}>No</button>
                        </div>
                    </div>
                </div>
                <div className={ButtonHandller === '4' ?'Section3-qu' : 'd-none'}>
                    <div>
                        <p>Are you traveling with children?</p>
                        <div>
                            <button className={BtnChecked.child === true ?  '' : 'section3-select'} ref={BTNRef}  name='yes' value='Are you traveling with children?' onClick={BTNHandller}>Yes</button>
                            <button className={BtnChecked.child === false ? ''  :'section3-select' } ref={BTNRef}  name='no' value='Are you traveling with children?' onClick={BTNHandller}>No</button>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <p>Are you traveling with pets?</p>
                        <div>
                            <button className={BtnChecked.Pet === true ?  '' : 'section3-select' } ref={BTNRef} name='yes' value='Are you traveling with pets?' onClick={BTNHandller}>Yes</button>
                            <button className={BtnChecked.Pet === false ? '' : 'section3-select' } ref={BTNRef} name='no' value='Are you traveling with pets?' onClick={BTNHandller}>No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}